 .testsss {
     text-align: right !important;
 }

 body {
     background-color: White;
     margin: 0px 0px 0px 0px;
     padding: 0;
     border: none;
     text-align: left;
     font-family: Arial, Verdana, Calibri, Tahoma;
     font-size: 12px;
     color: #000000;
 }

 /* .content-main-wrapper {
     padding: 0;
 } */
.width50{
    width: 520px;
}
 .text-right {
     text-align: right;
 }

 .text-left {
     text-align: left;
 }

 .pl-0 {
     padding-left: 0 !important;
 }

 .pr-0 {
     padding-right: 0 !important;
 }

 .ml-0 {
     margin-left: 0 !important;
 }

 .mr-0 {
     margin-right: 0 !important;
 }

 .report-wrapper-main {
     width: 900px;
     margin: 0 auto !important;

     /*new*/
     /* width: 100%; */
 }

 .report_header {
     width: 650px;
     margin: 0 auto !important;
 }

 .box-space {
     padding-top: 10px;
     padding-bottom: 10px;
 }

 .logobrand {
     min-height: 50px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .logobrand img {
     max-width: 112px;
 }

 .brand-para {
     font-size: 12px;
     line-height: 15px;
     margin-bottom: 0;
 }


 .tablestyleControls {
     font-family: arial;
     font-size: 10pt;
     width: 100%;
     color: #3D3D3D;
     border: 1px solid #CDCDCD;
 }

 .tablestyleControls td {
     border: 1px solid #CDCDCD;
     width: 0;
 }

 .tablestyleControls td .ant-row.ant-form-item {
     margin-bottom: 0px !important;
 }

 .submit-btn-btm {
     display: flex !important;
     margin: 5px 0 0 auto !important;
     height: 26px !important;
     align-items: center !important;

 }

 .test {
     text-align: right !important;
     background-color: rebeccapurple;
 }

 .mr-8{
    margin-right: 8px;
 }

.cursor-not-allowed{
    cursor:not-allowed
}

.customer_td{
    width: 75.4% !important;
}
 @media all {
    .page-break {
      display: none;
    }
  }
  
  /* @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  } */
  /* tfoot
  {
      display: table-row-group;
  } */


  @media screen and (max-width: 1300px) {
    .ship_region_report_4{
        width: 1000px !important;
        margin: 0 auto;
    }
  }

 @media only screen and (min-width: 767px) {
     .input-width {
         width: 200px !important;
     }
 }

 @media (min-width: 576px) {
     .text-sm-right {
         text-align: right !important;
     }

     .pl-sm-0 {
         padding-left: 0 !important;
     }
 }


 @media only screen and (max-width: 767px) {
     .input-width {
         width: 100%;
     }

     .report-wrapper-main {
         width: 100%;
     }

     .tablestyleControls td {
         width: 100% !important;
         display: block;
         border: 0;
     }

     .tablestyleControls td.no-border-btm {
         border-bottom: 0px;
     }

     .tablestyleControls td+td {
         border-bottom: 1px solid #c1c1c1;
     }

     .submit-btn-btm {
         display: flex !important;
         margin: 5px 0 0 0 !important;
     }
 }

 .ant-table-summary:has(.shipped_region_wise){
    display: table-row-group;
 }


 @media print {
      /* @page {
         size: A4;
         margin: 11mm 17mm 17mm 17mm;
       } */
    footer {
      position: fixed;
      bottom: 0;
    }
  
    html, body {
      width: 210mm;
      height: 297mm;
    }
    .maxWidth{
       width: 1150px !important;
       margin: 0 50px !important;
    }
    .customer_td{
        width: 76.5% !important;
    }
    /* .row{
        margin: 0 !important;
    }
    .report-wrapper-main{
        margin: 0 !important;
    } */

  }
 /* report-wrapper-main header_class */