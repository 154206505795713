.styleContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.styleBody {
    height: 95%;
    box-shadow: 0 0 17px -2px rgb(0 0 0 / 25%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.styleBodyPendingSummary {
    box-shadow: 0 0 17px -2px rgb(0 0 0 / 25%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
@media print {
    .pending-pod-print{
        /* width: 1000px !important; */
    }
    .pending-pod-print .ant-table-cell{
        word-break: keep-all !important;
        font-size: 8px !important;
        padding: 2px !important;
    }
    .pending-pod-th-print{
        width: 60px !important;
    }
}

.h-100 {
    height: 100%;
}

.styleFooter {
    border-top: 1px solid #c9c5c5;
    height: 5%;
    display: flex;
    align-items: center;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.b-radius-5 {
    border-radius: 5px;
}

.b-radius-10 {
    border-radius: 10px;
}

.w-100 {
    width: 100%;
}

.ant-modal.modal-40 {
    width: 40% !important;
}

.pbpx-5 {
    padding-bottom: 5px;
}

.pbpx-10 {
    padding-bottom: 10px;
}

.pbpx-15 {
    padding-bottom: 15px;
}

.pbpx-20 {
    padding-bottom: 20px;
}

.pbpx-25 {
    padding-bottom: 25px;
}

.pbpx-30 {
    padding-bottom: 30px;
}

.pbpx-35 {
    padding-bottom: 35px;
}

.pbpx-40 {
    padding-bottom: 40px;
}

.pbpx-45 {
    padding-bottom: 45px;
}

.pbpx-50 {
    padding-bottom: 50px;
}

.ptpx-5 {
    padding-top: 5px;
}

.ptpx-10 {
    padding-top: 10px;
}

.ptpx-15 {
    padding-top: 15px;
}

.ptpx-20 {
    padding-top: 20px;
}

.ptpx-25 {
    padding-top: 25px;
}

.ptpx-30 {
    padding-top: 30px;
}

.ptpx-35 {
    padding-top: 35px;
}

.ptpx-40 {
    padding-top: 40px;
}

.ptpx-45 {
    padding-top: 45px;
}

.ptpx-50 {
    padding-top: 50px;
}

.mbpx-5 {
    margin-bottom: 5px;
}

.mbpx-10 {
    margin-bottom: 10px;
}

.mbpx-15 {
    margin-bottom: 15px;
}

.mbpx-20 {
    margin-bottom: 20px;
}

.mbpx-25 {
    margin-bottom: 25px;
}

.mbpx-30 {
    margin-bottom: 30px;
}

.mbpx-35 {
    margin-bottom: 35px;
}

.mbpx-40 {
    margin-bottom: 40px;
}

.mbpx-45 {
    margin-bottom: 45px;
}

.mbpx-50 {
    margin-bottom: 50px;
}

.mtpx-5 {
    margin-top: 5px;
}

.mtpx-10 {
    margin-top: 10px;
}

.mtpx-15 {
    margin-top: 15px;
}

.mtpx-20 {
    margin-top: 20px;
}

.mtpx-25 {
    margin-top: 25px;
}

.mtpx-30 {
    margin-top: 30px;
}

.mtpx-35 {
    margin-top: 35px;
}

.mtpx-40 {
    margin-top: 40px;
}

.mtpx-45 {
    margin-top: 45px;
}

.mtpx-50 {
    margin-top: 50px;
}

.mlpx-5 {
    margin-left: 5px;
}

.mlpx-10 {
    margin-left: 10px;
}

.mlpx-15 {
    margin-left: 15px;
}

.mlpx-20 {
    margin-left: 20px;
}

.mlpx-25 {
    margin-left: 25px;
}

.mlpx-30 {
    margin-left: 30px;
}

.mlpx-35 {
    margin-left: 35px;
}

.mlpx-40 {
    margin-left: 40px;
}

.mlpx-45 {
    margin-left: 45px;
}

.mlpx-50 {
    margin-left: 50px;
}

.mrpx-5 {
    margin-right: 5px;
}

.mrpx-10 {
    margin-right: 10px;
}

.mrpx-15 {
    margin-right: 15px;
}

.mrpx-20 {
    margin-right: 20px;
}

.mrpx-25 {
    margin-right: 25px;
}

.mrpx-30 {
    margin-right: 30px;
}

.mrpx-35 {
    margin-right: 35px;
}

.mrpx-40 {
    margin-right: 40px;
}

.mrpx-45 {
    margin-right: 45px;
}

.mrpx-50 {
    margin-right: 50px;
}

.pos-right-10 {
    right: 10px;
}

.color-green {
    color: green !important;
}

.color-red {
    color: red !important;
}

.border-rad-4 {
    border-radius: 4px !important;
}

.border-rad-5 {
    border-radius: 5px !important;
}

.border-rad-10 {
    border-radius: 10px !important;
}

.relative {
    position: relative;
}


.pop-search-gen {
    position: absolute !important;
    top: -65px !important;
    right: 0px !important;
    border-radius: 10px !important;
}

.pop-170 {
    width: 170px !important;
}

.pop-220 {
    width: 220px !important;
}

.pop-11em {
    width: 11em !important;
}

.pop-25vw {
    width: 25vw !important;
}

.records-text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #272727;
    min-width: 150px;
}

.cross-btn {
    margin: 5px 0;
    position: relative;
    right: 10px;
    background: #ececec;
}

.bottom-records-numbers {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #272727;
    min-width: 150px;
}

.user-right-table-txt {
    padding-top: 3px;
    margin-left: 10px;
}

.search-pop-style {
    position: absolute !important;
    top: -70px !important;
    right: 0px !important;
    border-radius: 10px !important;
}

.w-11-em {
    width: 11em !important;
}

.close-button-sub {
    margin: 5px 0 !important;
    right: 10px !important;
    background: #ececec !important;
}

.tables-main-row-new {
    width: 100% !important;
    padding: 10px 10px 0 10px !important;
}

.records-count {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #272727;
    min-width: 150px;
}

/* body  */
.bodyStyle {
    /* display: flex;
    flex-direction: column; */
    flex: 1;
    position: relative;
}

.styleContent {
    display: flex;
    height: auto !important;
    overflow-y: auto;
}

.styleForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 78vh;
    overflow-y: auto;
    width: 100%;
    padding-top: "10px";
}

/* custom css date picker */
.dateInput {
    width: 100%;
    border-radius: 4px !important;
}

.ant-picker-dropdown {
    /* width: 22em; */
}

.ant-picker-panel-container {
    /* border-radius: 20px !important; */

}

.ant-picker-header {
    /* width: 420px !important; */
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
    /* width: 20vw !important; */
}

/* scroll  */
.styleForm::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.styleForm::-webkit-scrollbar {
    cursor: pointer !important;

}

*::-webkit-scrollbar-thumb {
    background-color: #b1b1b1;
    border-radius: 10px;
    box-shadow: none;
    border-color: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #858585;
    cursor: grab;
}

/*  */

.styleForm .styleInput {
    border-radius: 5px;

}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {

    padding: 5.3px 15px !important;
}

.styleForm .styleInput:hover {
    border-color: gray;
    color: gray
}

.styleForm .styleInput:focus {
    box-shadow: none;
    border-color: gray;
}

.modalStyle {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 5px;
    padding-right: 5px;
}


.delete-Button {
    margin-left: 10px;
    color: rgb(211, 47, 47) !important;
}

.edit-Button {
    margin-left: 5px !important;
}

.ant-modal-close-x {
    width: 56px !important;
    height: 46px !important;
    color: rgb(211, 47, 47) !important;
    line-height: 46px !important;
}

.ant-tabs-nav-list {
    width: 100% !important;
    background: #FFF !important;
    border-radius: 5px;

}

.ant-select-multiple .ant-select-selection-item-remove {
    line-height: 10px !important;
}

.ant-tabs-nav-list .ant-tabs-tab {
    padding: 2px 0 !important;
    justify-content: center;
    color: gray;
    font-weight: 400;
    border: 1px solid #5c6e7e !important;
    /* border-radius: 5px !important; */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    /* border-radius: 10px; */
}

.ant-tabs-tab:hover {
    color: black !important;
}

.ant-tabs-ink-bar {
    background: #000 !important;
    border-bottom-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;

}

.ant-tabs-tab.ant-tabs-tab-active {
    background: #5c6e7e !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.tabs .ant-table-wrapper {
    padding: 0px !important;
}

.styleTab {
    padding-top: 10px;
    width: 100%;
    /* height: 54vh; */

}

button.ant-btn.ant-btn-circle.ant-btn-icon-only.iconBtn.undoBtn {
    color: #df1010;
}

.tabs {
    height: auto;
    /* padding: 0 0 10px 0; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */

}

.ant-select-arrow+.ant-select-clear {
    margin-top: -10px;
}

.ant-table-row {
    line-height: 10px !important;
}

.ant-table-thead {

    line-height: 15px !important;
}

.ant-table-pagination.ant-pagination {
    margin: 14px 0 !important;
}

/* modalStyle */
.modalButton {
    border: 1px solid #3c4b58 !important;
    border-radius: 5px;
    box-shadow: none !important;
    color: white;
}

.modalButton:hover {
    color: #8b8b8b;
    border-color: #8b8b8b !important;
    box-shadow: none !important;

}

.modalButton:focus {
    color: #060606;
    box-shadow: none !important;
}

.modalButton::after {
    color: #060606;
    box-shadow: none !important;
}

.modalActionBTN {
    background: #fff !important;
    border: none !important;
}

.modalActionBTN .ant-btn[disabled] {
    border: none;
    background: #fff !important;
    box-shadow: none !important;

}

.addCombinationBtn {
    border: 1px solid #060606 !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    color: #060606;
    margin-bottom: 10px;
}

.addCombinationBtn:hover {
    color: #8b8b8b !important;
    border-color: #8b8b8b !important;
    box-shadow: none !important;
}

.addCombinationBtn:focus {
    color: #060606 !important;
    box-shadow: none !important;
}

.addCombinationBtn::after {
    color: #060606 !important;
    box-shadow: none !important;
}

.addCombinationBtn.ant-btn[disabled] {
    color: gray !important;
    border-color: gray !important;
    background: #f5f5f5 !important;
    box-shadow: none !important;

}

.ant-form-item-label>label {
    width: 89%;
    justify-content: flex-start;
}

/* .styleForm .ant-form{
    width: 30%;
}

.styleForm .ant-form-item{
    margin-bottom: 8px !important;
} */
.ant-dropdown .ant-dropdown-placement-bottomRight {
    left: 160px !important;
}

.testStyle {
    width: 100%;
}

.styleFormUpper {
    flex-direction: column;
}

.content-main-wrapper {
    flex: 1;
    padding: 10px 20px 0px 20px;
}

.site-page-header .ant-page-header-heading-extra {
    display: flex;
}

.notfoundContainer .not-found-icon {
    margin-right: 7px;
}

.ant-pagination-item-active {
    font-weight: 400;
}

.ant-menu-horizontal {
    line-height: 40px !important;
}

.ant-menu-title-content .anticon.anticon-down {
    font-size: 10px;
    font-weight: 500;
    top: -3px;
    left: 0px;
    position: relative;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
    border-bottom: 2px solid #b2c5d9 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
    color: #b2c5d9 !important;
}

.ant-menu-submenu-horizontal.ant-menu-submenu-selected .ant-menu-title-content {
    color: white !important;
}

.ant-select-item-option-selected .ant-select-item-option-content {
    color: white !important;
}

.ant-select-item-option-selected .ant-select-item-option-state {
    color: white !important;
}

.cardDesignNew.cardTitle {
    /* border: 1px solid #c4c4c4 !important; */
}

.cardDesignNew .ant-card-head {
    border-bottom: 1px solid #c4c4c4 !important;
    background: #5e6e7e;
    /* border-radius: 10px 10px 0 0; */
    color: white;
    font-weight: 400;
    min-height: initial !important;
}

.cardDesignNew .ant-card-head-title {
    padding: 3px 0;
    font-size: 14px;

}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav,
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
    margin: 0 0 8px !important;
}

.ant-card-body {
    padding: 10px 15px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.ant-menu-submenu-vertical .ant-menu-title-content .anticon.anticon-down {
    display: none;
}

.p-btn {
    border-radius: 4px;
}

.ant-btn-danger {
    background-color: rgb(211, 47, 47) !important;
    border: rgb(211, 47, 47) !important;
}

.no-border-first {
    border-radius: 5px !important;
    border: 1px solid #c4c4c4 !important;
}

.no-border-first .ant-input {
    border: 0px !important;
}

.mainProductContainer.new-style {
    height: 100%;
    min-height: 88vh;
    overflow-y: hidden;
}

.id-field-style.ant-input {
    border: none !important;
    font-weight: 500;
}

.minimize-button-style {
    border-radius: 5px;
    font-weight: 600;
}

.row-color-default {
    color: black;
}

.row-color-selected {
    color: #0572f2;
}

.p-10-all {
    padding: 10px;
}

.radius-5 {
    border-radius: 5px;
}

.modal-form-main .ant-form-item-label {
    padding-bottom: 0px !important;
}

.label-height-adjust .ant-form-item-label {
    white-space: nowrap !important;
}
.width400{
    width: 450px !important;
}
.image-nu{
    height: 50px;
    width: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(239, 239, 239);
    border-image: initial;
    border-radius: 50%;
    overflow: hidden;
}
.image-nu .ant-image-mask:hover {
    opacity: 0;
}
@media only screen and (max-width: 1280px) {
    .ant-modal.modal-40 {
        width: 80% !important;
    }
}

@media only screen and (max-width: 767px) {

    .records-count {
        position: static;
    }

    .ant-form-item-label>label {
        height: initial !important;
        font-size: 13px !important;
    }

    span.anticon.anticon-ellipsis {
        color: white;
    }

    .ant-menu-horizontal>.ant-menu-item-active,
    .ant-menu-horizontal>.ant-menu-item:focus-visible,
    .ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:focus-visible {
        box-shadow: none;
        outline: none;
    }

    .ant-tabs-nav-list {
        flex-direction: column !important;
    }

    .ant-tabs-nav-list .ant-tabs-tab {
        width: 100% !important;
    }

    .ant-tabs-tab+.ant-tabs-tab {
        margin: 5px 0 0 0 !important;
    }

    .site-page-header button.ant-btn.ant-btn-link {
        padding: 0 !important;
    }

    .site-page-header button.ant-btn.ant-btn-link span {
        font-size: 12px !important;
    }
}

@media only screen and (max-width:576px) {
    .ant-form-item .ant-form-item-label {
        line-height: initial !important;
        padding-bottom: 2px !important;
    }

    .label-mob-space .ant-form-item .ant-form-item-label {
        padding-bottom: 0px !important;
       
    }

    .ant-form-item .ant-form-item-label .ant-form-item-label-left {
        text-align: center !important;
    }


    .country-spaceremove-mob {
        margin-left: 0px !important;
    }

}

@media only screen and (max-width:444px) {
    /*      .site-page-header .ant-page-header-heading-left {
               display: table!important;
               margin: 9px auto 0px auto!important;
           } */
}


@media only screen and (max-width: 1632px){

    .business_header_oms{
        width: 1400px !important;
    }
}

@media only screen and (max-width: 1432px){

    .business_header_oms{
        width: 1300px !important;
    }
}

@media only screen and (max-width: 1332px){

    .business_header_oms{
        max-width: 960px !important; 
        /* max-width: 1200px !important;  */

    }
    .business_class_oms{
        /* max-width: 960px !important;  */
        max-width: 1200px !important; 
    }

    


    /* .business_header_oms {
        max-width: 1200px !important;
    } */

    
}

@media only screen and (max-width: 1202px){

    .business_header_oms{
        max-width: 950px !important; 

    }
    .business_class_oms{
        max-width: 950px !important; 
    }

    
    
}
